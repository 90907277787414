@import url(https://fonts.googleapis.com/css2?family=Stalemate&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-container {
    padding: 2rem 10rem;
    /* padding-top: 10rem;     */
    /* flex: 1;         */
}

.logoWidth{
    width: 100%;
}

/* .btn {
    background-color: #312C71;
} */

label {
    font-size: 12pt;
}

@media (max-width: 1366px) {
}

@media (max-width:1920px) {
}

@media (max-width:520px) {
    .flex-container {
        padding: 1rem 1rem;
    }
    .logoWidth{
        width: 100%;
    }
}

input[type="file"] {
    display: none;
}

.custom-file-upload{
    margin-top: 43px;
    width: 100%;
    background-color: #E95B31;
    padding: 0.7rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #E95B31;
    text-align: center;
    font-size: 15px;
}

.custom-file-send{
    margin-top: 43px;
    width: 100%;
    background-color: #312C71;
    padding: 0.7rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #312C71;
    text-align: center;
    font-size: 15px;
}

.custom-file-upload i{
    padding-right: 8px;
}

.box-container{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.box-container div{
    text-align: center;
}

.box-container img{
    width: 50%;
    border-radius: 20px;
}

.text-bold{
    font-weight: bold;
}
